import { redirect } from "@remix-run/node"
import type { DataFunctionArgs } from "@remix-run/node"

export async function loader({ request }: DataFunctionArgs) {
  const redir = {
    "/clanky": "/zajimavosti",
    "/clanky/hrozba-z-jihu-tabor-2020": "/probehlo/2020/hrozba-z-jihu-tabor",
    "/clanky/argo-tabor-2021": "/probehlo/2021/argo-tabor",
    "/clanky/aersgath-taborovy-pribeh-2022":
      "/probehlo/2022/aersgath-taborovy-pribeh",
    "/clanky/tabor-kaclehy-2022": "/probehlo/2022/tabor-kaclehy",
    "/clanky/tajna-akce-podzimky-2022": "/probehlo/2022/tajna-akce-podzimky",
    "/clanky/velikonocni-vyprava-2022": "/probehlo/2022/velikonocni-vyprava",
    "/clanky/mecobranik-2023": "/probehlo/2023/mecobranik",
  }[new URL(request.url).pathname]
  if (redir) throw redirect(redir, { status: 307 })
  throw new Response(null, { status: 404 })
}

export default function FourOhFour() {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center">
      <h1 className="text-3xl font-bold">404</h1>
      <p className="text-xl">Stránka nenalezena</p>
    </div>
  )
}
